import AxiosService from "../../base/portal_env_config/axiosService";
import { paths } from "../../base/portal_env_config/urlconstants";
import { BaseResponse } from "../../model/base/baseresponse";

export default class ProfileService {
    public static async getProfileDetailsById(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_PROFILE_DETAILS_BY_ID, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async submitLanguages(languagepreference) {
        return AxiosService.httpPost(paths.UPDATE_LANGUAGES, languagepreference).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async submitAddress(address) {
        return AxiosService.httpPost(paths.UPDATE_ADDRESS, address).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async submitInterests(interests) {
        return AxiosService.httpPost(paths.SET_INTEREST_LIST, interests).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getLanguages(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_LANGUAGES, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getInterestList(): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_INTEREST_LIST).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async updateProfilePic(reqBody) {
        return AxiosService.httpPost(paths.UPDATE_PROFILE_PIC, reqBody).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getProfileAddresses(params): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GET_PROFILE_ADDRESS, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async updatePrimaryUserProfile(requestBody): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.UPDATE_PRIMARY_USER, requestBody).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getOtpForAccountDeactivation(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.Get_OTP_FOR_ACCOUNT_DELETION, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

        
    public static async deleteUserAccount(params): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.DELETE_USER_ACCOUNT, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }
}