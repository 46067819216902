import { Component } from 'react';
export default class HSComponent  extends Component {
    props:any;
    state: any;

    constructor(props) {
        super(props);
    }
    
}
