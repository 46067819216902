import { LeadParams } from "../basket/leadParams";

export class LoginDetails {
    otpNumber: number;
    // password: string;
    // username: number;//mobile number
    username: string;
    source = 5;
    password = "";
    leadParams: LeadParams;
    // emailId: string;

    constructor(loginDetails: any = {}) {
        // this.username = parseInt(loginDetails.username);
        this.username = loginDetails.username;
        this.otpNumber = parseInt(loginDetails.otpNumber);
        // this.emailId = loginDetails.emailId;
    }
}
