
import { connect } from "react-redux";
import layoutReducers from './layout.reducers';
import layoutActions from './layout.actions';
import authActions from './../../auth/auth.actions';
import diagnosticActions from '../diagnostics/diagnostic.actions';
import { pagemoduleActions } from '../../base/pagemoduleutil/pagemodule.redux';
import checkoutActions from "../checkout/checkout.actions";
import { hsLocAction } from "../util-components/hs-location/hs-location.redux";

const layoutConnect = (Layout) => {
   return connect(layoutReducers, {
      ...layoutActions, ...authActions, ...diagnosticActions,
      ...pagemoduleActions,  ...checkoutActions, ...hsLocAction
   })(Layout)
};

export default layoutConnect;