import { DoctorDetails } from '../employee/doctordetails';
import { PocAdviseData } from '../poc/poc_advise_data';
import { ProfileDetailsVO } from '../profile/profilevisitdetailsvo';
import { TransactionHistory } from './transactionhistory';
import { Address } from '../poc/address';
import { Payment } from './payment';
import { BasketDiscount } from '../package/basketdiscount';
import { BaseResponse } from '../base/baseresponse';
import { LeadParams } from './leadParams';

export class InvoiceBaseDetails extends BaseResponse {

    public static CANCELLATION_STATUS_ACTIVE = 0;
    public static CANCELLATION_STATUS_USER_CANCELLED = 1;
    public static CANCELLATION_STATUS_VENDOR_CANCELLED = 2;

    public static ICS_INIT = 0;
    public static ICS_COMPLTETED = 5;

    public static INSTRUMENT_TYPE_INVOICE = 0;
    public static INSTRUMENT_TYPE_CREDIT_MEMO = 1;
    public static INSTRUMENT_TYPE_ESTIMATE_SLIP = 2;
    public static INSTRUMENT_TYPE_PRE_INVOICE = 3;
    public static INSTRUMENT_TYPE_PRE_CREDIT_MEMO = 4;

    public static REFUND_NOT_INITIATED = 0;
    public static REFUND_INITIATED = 1;
    public static REFUND_INPROGRESS = 2;
    public static REFUND_COMPLETED = 3;
    public static REFUND_HOLD = 4;
    public static REFUND_ERROR = 5;
    public static NON_REFUNDABLE = 6;

    //card types
    public static PC = 1;//Privilege Card
    public static SCHS = 2;//Senior Citizen Health Scheme
    public static CGHS = 3;//Central Government Health Scheme
    public static JCHS = 4;//Jain Community Health Scheme

    public orderId: string;
    public invoiceId: string;
    public referenceId: string;
    public baseInvoiceId: string;
    public parentInvoiceId: string;
    public patientProfileId: number;
    public parentProfileId: number;
    public profileId: number;
    public patientRelationship: number;
    public parentServiceId: number;
    public doctorId: number;
    public serviceId: number;
    public serviceName: string;
    public parentServiceName: string;
    public pocId: number;
    public appId: number;
    public brandId: number;
    public empId: number;
    public adviceId: number;
    public bookingSource: number;
    public paymentSource: number;
    public userPackageId: number;
    public packageName: string;
    public basketDiscount: BasketDiscount[] = new Array<BasketDiscount>();
    public invoiceCompletionStatus: number;
    public cancellationStatus: number;
    public refundStatus: number;
    public cancellationExpiryDate: string;
    public payment: Payment = new Payment();

    public instrumentType: number;

    public referredByBooking: any;

    public createdTimestamp: number;
    public updatedTimestamp: number;
    public orderPlacedTimestamp: number;

    public pdfUrlWithHeader: string;
    public pdfUrlWithoutHeader: string;

    public deliveryAddress: Address;
    public deliveryType: number;
    public deliveryAmount: number;
    public transactionHistoryList: TransactionHistory[] = new Array<TransactionHistory>();
    public patientProfileDetails: ProfileDetailsVO = new ProfileDetailsVO();
    public pocDetails: PocAdviseData;
    public doctorDetail: DoctorDetails;
    public actualDate: number;
    public addToConsultationQueue: boolean;
    public packageDiscountSource: number;
    public excelPackage: boolean;

    public internalBrandRequest: boolean;
    public inPatientBilling: boolean;
    public leadParams: LeadParams;

    public proofDocumentUrlList: Array<String>;
    public privilegeCardChosen: boolean;
    public privilegeCardType: number;
}
