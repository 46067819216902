import { BaseResponse } from '../base/baseresponse';
import { EmergencyContact } from './emergencycontact';
import { ContactInfo } from './contactinfo';
import { ProfileDetails_others } from './profiledetails_others';
import { Social } from './social';
import { Labcertifiedpdf } from '../phr/labcertifiedpdf';
import { CorporateDetails } from './corporatedetails';
import { UpdatedBy } from './updatedby';
import { LanguagePreference } from '../doctor/languagepreference';

export class ProfileDetailsVO extends BaseResponse {
	// privilegeType
	public static PRIVILEDGE_CARD_TYPE = 1;
	public static SENIOR_CITIZON_CARD_TYPE = 2;
	public static CGSH_CARD_TYPE = 3;
	public static COMMUNITY_TYPE = 4;

	public age: string;
	public title: string = '';
	public contactInfo: ContactInfo = new ContactInfo();
	public emergencyContact: EmergencyContact = new EmergencyContact();
	public dob: number;
	public displayName: string;
	public fName: string;
	public gender: string;
	public imageData: string;
	public profilePic: string;
	public relationShip: number;
	public relationShipId: number;
	public healthScore: number;
	public height: Map<String, String> = new Map<String, String>();
	public profileId: number;
	public parentProfileId: number;
	public lName: string;
	public others: ProfileDetails_others = new ProfileDetails_others();
	public social: Social = new Social();
	public weight: Map<String, String> = new Map<String, String>();
	public createdTime: number = Date.now();
	public portal: boolean;
	public updateMobile: boolean;
	public updateEmail: boolean;
	public otpNumber: string;
	public orgCode: string;
	public alcholic: boolean;
	public profileCompletion: number;
	public healthscorepdf: string;
	public labcertifiedpdf: Array<Labcertifiedpdf>;
	public providedOnlyAge: boolean;
	public referralCode: string;
	public otpNotRequired: boolean;
	public brandId: number;

	public corporateDetailsList: Array<CorporateDetails> = new Array<CorporateDetails>();
	public registrationSource: number;

	public updatedBy: UpdatedBy;

	public languagePreferenceList: Array<LanguagePreference> = new Array<LanguagePreference>();
	public serviceLine: string;
	public heightValue: string;
	public weightValue: string;
	public bloodGroup: string;
	// S3Credential  s3Credential;
	//BrandDetailsWithReferralCode  brandDetails;

	public privilegeType: Array<number>;
	public proofDocumentUrlList: Array<String>;
	//local 
	public isSelected: boolean = false;
	public tempProfilePic: string;
}
