import { paths } from '../../base/portal_env_config/urlconstants';
import AxiosService from '../../base/portal_env_config/axiosService';

export class HealthLockerService {

    public static uploadedFiles = [];
    public static getTestReports(reqParams): Promise<any> {
        return AxiosService.httpGet(paths.GET_PHR_REPORTS, reqParams).then(res => {
            return Promise.resolve(res.data);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }
    public static searchTest(reqParams): Promise<any> {
        return AxiosService.httpGet(paths.SEARCH_TEST_FOR_REPORT, reqParams).then(res => {
            return Promise.resolve(res.data);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static uploadReports(reqParams): Promise<any> {
        return AxiosService.httpPost(paths.UPLOAD_INVASIVE, reqParams).then(res => {
            return Promise.resolve(res.data);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static uploadFileService(reqParams): Promise<any> {
        return AxiosService.httpPost(paths.UPLOAD_FILE, reqParams).then(res => {
            return Promise.resolve(res.data);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }
    public static createModal(id, content) {
        return <div className="modal" id={id}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body" style={{ minHeight: '300px' }}>
                        {content}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    }
}