import React, { Component } from 'react';
import { Calendar } from 'react-date-range';
import { addYears } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default class CalendarComponent extends Component {
    props: any;
    state = {
        selectedDate: new Date(),
        showCalendar: false
    }
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.dateMillis && prevProps.dateMillis !== this.props.dateMillis) {
            this.setState({
                selectedDate: new Date(this.props.dateMillis)
            });
        }
    }

    handleSelect = (date) => {
        this.setState({
            selectedDate: date,
            showCalendar: false
        });
        this.props?.onChange?.(date);
    }

    render() {

        return (
            <React.Fragment>
                <style jsx global>{`
                .rdrMonth {
                    width: 100%;
                    padding: 0;
                }
                .rdrMonthAndYearWrapper {
                    padding-top: 0;
                }
                `}</style>
                <div className="daterangepicker">
                    <input type="text" placeholder="dd/mm/yyyy" value={this.state.selectedDate.toLocaleDateString('en-GB')} readOnly />
                    <i className="fa fa-calendar pointer-cursor" onClick={() => { this.setState({ showCalendar: true }) }} ></i>
                </div>
                {this.state.showCalendar &&
                    <Calendar
                        date={this.state.selectedDate}
                        minDate={addYears(new Date(), -120)}
                        maxDate={new Date()}
                        onChange={this.handleSelect}
                    />
                }

            </React.Fragment>
        );
    }
}

