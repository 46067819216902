import React, { Component } from "react";
import Router, { withRouter } from "next/router";
import { withTranslation } from "../../../i18n";
import connect from "./profile.connect";
import ErrorBoundary from "../layout/widgets/errorboundary/errorboundary.component";
import CommonUtil from "../../base/utils/commonUtil";
import DateRangePicker from "../util-components/daterangepicker/daterangepicker";
import Validations from "../../base/utils/validations";
import RegistrationService from "../registration/registration.service";
import { Config } from "../../base/portal_env_config/config";
import HSComponent from "../../auth/hscomponent";
import ProfileService from "./profile.service";
import "./profile.style.scss";
import Format from "../../base/utils/format";
import ToastComponent from "../layout/widgets/toast/toast.component";
import Auth from "../../auth/auth";
import StorageUtil from "../../auth/util/storageUtil";
import FileUtil from "../../base/utils/fileutil";
import { HealthLockerService } from "../healthlocker/healthlocker.service";

class primaryuserSelectionComponent extends HSComponent {
  props: any;
  state = {
    createNewUser: false,
    hasPrimaryuser: false,
    userDetails: {
      proofDocumentUrlList: [],
    },
    errorMsg: "",
    isLoading: false,
    isDOB: true,
    selectedValue: 0,
    ageYears: 0,
    ageMonths: 0,
    newMemberDate: new Date(),
    uploadedProofDocuments: [],
    disabled: false,
    genderSet: 0,
    lisUserDetails: {
      lisExistingUsers: [],
    },
    showFamilyMembersRelationshipModal: false,
    isNewUser: false
  };
  titlesList: Array<string> = [
    "Mr",
    "Miss",
    "Mrs",
    "Master",
    "Baby",
    "Baby of",
    "Baby Boy",
    "Baby Girl",
    "Dr",
    "Prof",
    "Capt",
  ];
  minAge = new Date().setFullYear(new Date().getFullYear() - 120);
  maxAge = new Date();
  maleSet = ["Mr", "Baby Boy", "Master"];
  femaleSet = ["Miss", "Baby Girl", "Mrs"];


  constructor(props) {
    super(props);
    this.state.lisUserDetails = this.props?.lisUserDetails;

  }

  getLisUsersUi = () => {

    let lisUserDetails = { "lisExistingUsers": [], "contactInfo": { 'mobile': '' } };
    let lisExistingUsers = [];
    lisUserDetails = (this.state?.lisUserDetails?.lisExistingUsers?.length > 0) ? this.state?.lisUserDetails : this.props?.lisUserDetails;

    lisExistingUsers = lisUserDetails?.lisExistingUsers;
    return lisExistingUsers?.map((lisUser, index) => {
      return (
        <div>


          <React.Fragment key={`${Math.random()}${lisUser?.referenceId}`}>
            <div className="user_card">
              <div className="d-flex ">
                <div className="user_image ">
                  <img
                    src="https://hsappimages.s3.ap-south-1.amazonaws.com/other/vdc/person.png"
                    alt="User Image"
                  />
                </div>

                <div className="user_detail ml-3">
                  <p className="text_header">

                    {CommonUtil.getFormattedName(
                      lisUser?.title,
                      lisUser?.fName,
                      lisUser?.lName
                    )}
                  </p>
                  {(lisUser?.memberShipId && lisUser?.memberShipId > 0) ? <p>Registration ID:{lisUser?.memberShipId}</p> : ''}
                  <p>{Format.toDate(lisUser?.createdTime, 'dd MMMM yyyy')}</p>
                </div>
              </div>
              <div className="user_check pr-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={lisExistingUsers[index].primaryUser == true}
                    value={lisExistingUsers[index].primaryUser}
                    onChange={(e) => {
                      let hasPrimaryuser = this.state.hasPrimaryuser;
                      lisExistingUsers?.forEach((eachUser, j) => {
                        if (eachUser.referenceId == lisUser.referenceId) {
                          hasPrimaryuser = true;
                          eachUser["primaryUser"] = true;
                          eachUser.contactInfo.mobile =
                            lisUserDetails.contactInfo.mobile;
                          eachUser["relationShip"] = 0;
                        } else {
                          eachUser["primaryUser"] = false;
                          eachUser["relationShip"] = 13;
                        }
                      });
                      lisUserDetails.lisExistingUsers = lisExistingUsers;

                      this.setState({
                        lisUserDetails: lisUserDetails, hasPrimaryuser: hasPrimaryuser
                      }, () => {
                        console.log('checkuserlist', JSON.stringify(lisUserDetails))
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>


        </div>
      );
    });
  };

  openNewUserRegisterationModal = () => {
    this.setState({
      userDetails: {
        proofDocumentUrlList: []
      }
    })
    $("#newUserRegistrationModal").modal("show");
  };

  onTitleChange() {
    let userDetails: any = this.state.userDetails;
    this.maleSet.forEach((x) => {
      if (x == userDetails.title)
        this.setState({
          userDetails: {
            ...userDetails,
            gender: "male",
          },
          // Set 1 for maleset
          genderSet: 1,
        });
    });
    this.femaleSet.forEach((x) => {
      if (x == userDetails.title)
        this.setState({
          userDetails: {
            ...userDetails,
            gender: "female",
          },
          //set 2 for femaleset
          genderSet: 2,
        });
    });
  }

  uploadFile = (event) => {
    RegistrationService.uploadedFiles = [
      ...RegistrationService.uploadedFiles,
      ...event.target.files,
    ];
    this.setState({ ...this.state });
  };


  async submitProfile(primaryUserDetails) {
    console.log("newuserdetails", JSON.stringify(primaryUserDetails));
    let dob;

    if (!this.state.isDOB) {
      if (this.state.ageYears > 0 || this.state.ageMonths > 0) dob = CommonUtil.getDobFromAge(this.state.ageYears, this.state.ageMonths)
      else dob = new Date().getTime()
      primaryUserDetails.dob = dob;
    }
    else {
      primaryUserDetails.dob = this.state.newMemberDate.getTime()
    }


    if (!this.isValidProfile(primaryUserDetails)) {
      this.setState({ disabled: false });
      return;
    }

    if (RegistrationService.uploadedFiles.length > 0) {
      await this.fileUpload(primaryUserDetails);
    }
    else {
      this.addProfileHandler(primaryUserDetails);
    }


  }

  addProfileHandler = (primaryUserDetails) => {
    primaryUserDetails.createdTime = new Date().getTime();
    primaryUserDetails.primaryUser = true;

    let lisUserDetails = this.props?.lisUserDetails;
    primaryUserDetails["contactInfo"]["mobile"] = lisUserDetails?.contactInfo?.mobile;
    console.log("newuserdetails&&", JSON.stringify(primaryUserDetails));

    lisUserDetails?.lisExistingUsers.forEach((user) => {
      user.primaryUser = false;
    })
    lisUserDetails?.lisExistingUsers.unshift(primaryUserDetails);
    this.setState({ lisUserDetails: lisUserDetails }, () => {
      console.log("newuserdetails", JSON.stringify(lisUserDetails));
    });
    $("#newUserRegistrationModal").modal("hide");
    this.props.openPrimaryUserSelectionPopUp();
    this.setState({
      createNewUser: false, hasPrimaryUser: true, isNewUser: true
    })
  }

  isValidProfile(userDetails): boolean {
    let { t } = this.props;
    let isValid = true;
    let selfMaxAge = new Date().setMonth(new Date().getMonth() - 1);
    if ((userDetails?.title == undefined || userDetails?.title == "undefined" || userDetails?.title == null || userDetails?.title == "") ||
      (userDetails?.fName == undefined || userDetails?.fName == null || userDetails?.fName == "") ||
      (userDetails?.gender == undefined || userDetails?.gender == null || userDetails?.gender == "") ||
      ((this.props?.isFamilyMemAdd || this.props?.isFamilyMemEdit) && (userDetails?.relationShip == undefined || userDetails?.relationShip == null || userDetails?.relationShip == 0))) {
      ToastComponent.warning(t('REGISTRATION.FIELDS_ERROR_TOAST'), { toastId: "profileErr" });
      isValid = false;
    }
    if (this.state?.genderSet == 1 && (userDetails?.gender).toLowerCase() != "male" ||
      this.state?.genderSet == 2 && (userDetails?.gender).toLowerCase() != "female") {
      ToastComponent.warning(t('REGISTRATION.GENDER_VALIDATION'), { toastId: "profileErr" });
      isValid = false;
    }

    if (userDetails?.dob == 0 || !Validations?.isValidDate(userDetails?.dob, selfMaxAge, this?.minAge)) {
      ToastComponent.warning(t('REGISTRATION.INVALID_DOB_TOAST'), { toastId: "profileErr" });
      isValid = false;
    }
    if (!this.props?.isFamilyMemAdd && !this.props?.isFamilyMemEdit) {
      if (userDetails?.contactInfo?.email == "" || userDetails?.contactInfo?.email == undefined || userDetails?.contactInfo?.email == null) {
        ToastComponent.warning(t('REGISTRATION.MAIL_TOAST'), { toastId: "profileErr" });
        isValid = false;
      }

    }
    // }
    else {
      if (userDetails?.dob == 0 || !Validations.isValidDate(userDetails?.dob, this?.maxAge, this?.minAge)) {
        ToastComponent.warning(t('REGISTRATION.INVALID_DOB_TOAST'), { toastId: "profileErr" });
        isValid = false;
      }
    }
    if (userDetails?.contactInfo?.email && (!Validations?.isValidEmail(userDetails?.contactInfo?.email)) && (userDetails?.contactInfo?.email + '').trim().length != 0) {
      ToastComponent.warning(t('REGISTRATION.INVALID_MAIL_TOAST'), { toastId: "profileErr" });
      isValid = false;
    }


    return isValid;
  }

  async fileUpload(userDetails) {

    await HealthLockerService.uploadReports({
      "userType": null,
      "profileId": this.props?.auth?.userDetails?.profileId || 0,
      "type": 2,
      "reportedDate": new Date().getTime(),
      "userId": this.props?.auth?.userDetails?.profileId || 0,
      "fileUrlList": [],
      "specialization": null,
      "parentProfileId": this.props?.auth?.userDetails?.profileId || 0,
      "testDetailList": [],
      "reportId": null,
      "hasFiles": (RegistrationService.uploadedFiles.length > 0) ? true : false,
      "name": null
    }).then(response => {
      console.log("Result: ", response);
      if (response.statusCode == 200 || response.statusCode == 201) {
        this.onFileUploadToS3(response, userDetails);
      }
    }).catch((err) => {
      console.log(err);

    })
  }

  async onFileUploadToS3(resData, userDetails) {
    let uploadedProofDocuments = [];

    this.props?.setLayoutLoading(true);
    await RegistrationService.uploadedFiles?.forEach(async (file, fIndex) => {
      this.props?.setLayoutLoading(false);
      await FileUtil.fileUploadToAwsS3(resData.s3Credential, file).then(filePath => {

        uploadedProofDocuments.push(filePath?.Location);
        userDetails.proofDocumentUrlList = uploadedProofDocuments;
        uploadedProofDocuments.length > 0 && this.setState({
          uploadedProofDocuments: uploadedProofDocuments, userDetails
        }, () => {
          uploadedProofDocuments.length > 0 ? this.addProfileHandler(userDetails) :
            ToastComponent.warning('Something went wrong please try again');
        })



      }).catch(err => { if (err) { this.props?.setLayoutLoading(false); } Promise.resolve(''); });
    });


  }

  updateprimaryuser = () => {
    ProfileService?.updatePrimaryUserProfile(this.state.lisUserDetails)
      .then((res) => {
        // $("#familyRelationshipModal").modal("hide");
        if (res?.data?.statusCode == 200 || res?.data?.statusCode == 201) {
          ToastComponent.success("Updated Successfully");
          Auth.setLoginResponse(res?.data?.lisExistingUsers[0]);
          this.props.setUserDetails(res?.data?.lisExistingUsers[0])
          StorageUtil.setDataLocalStorage(StorageUtil.LOGIN_KEY, res.data);
          Auth.setLanguage(res?.data?.lisExistingUsers[0]);
        }
        else {
          ToastComponent.warning("Something went wrong please try again");
        }

      }).catch((err) => {
        ToastComponent.warning("Something went wrong please try again");
      })
      .finally(() => {
        this.props.closeUserSelctionPopup(true);

        this.props.setLisUserDetails({});
        this.setState({ showFamilyMembersRelationshipModal: false })
      });
  };

  onSubmitClickHanlder = () => {
    this.updateprimaryuser();

  }


  componentWillUnmount() {
    $('#newUserRegistrationModal').modal('hide');
    // $('#familyRelationshipModal').modal('hide');
    this.setState({ showFamilyMembersRelationshipModal: false, createNewUser: false, isNewUser: false });
    this.props.closeUserSelctionPopup(false);
    this.props.setLisUserDetails({});
  }


  render() {


    let t = this.props.t;
    let userDetails: any = this.state.userDetails;
    let lisUserDetails = JSON.stringify(this.state?.lisUserDetails) != '{}' ? this.state?.lisUserDetails : this.props?.lisUserDetails;
    let enableUploadProofDocuments = false;
    let userAge;
    let maxAgeToEnableUploadProofs = 60;
    let primaryuser = this.state?.lisUserDetails?.lisExistingUsers?.filter(
      (user) => {
        return user.primaryUser == true;
      }
    );

    this.state.isDOB
      ? (userAge = parseInt(
        CommonUtil.getAgeForall(this.state.newMemberDate?.getTime()).split(
          ","
        )[0]
      ))
      : (userAge = this.state.ageYears);
    userAge >= maxAgeToEnableUploadProofs
      ? (enableUploadProofDocuments = true)
      : (enableUploadProofDocuments = false);
    return (
      <React.Fragment>
        <ErrorBoundary>
          {(!this.state.showFamilyMembersRelationshipModal) ?
            <div className="container profile_sec">
              <div className="row">
                <div className="col-sm-12">
                  <div className='text_header mt-3 ml-2 mb-1'>Choose your default profile</div>
                  <p className='mt-1 ml-2 mb-3 cont_text'>We have found below profiles that are associated with your mobile number. Please choose a primary profile to continue.</p>


                  {this.getLisUsersUi()}

                </div>
                <div className="col-sm-12 mb-3">
                  {
                    !this.state.isNewUser &&
                    <div className="create_box d-flex">
                      <div className="create_new">
                        <div>
                          <p className="text_header">Create new profile</p>
                          <p className='user_detail'>Ignore the above & create a new profile.</p>
                        </div>
                      </div>
                      <div className="user_check pr-2">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                            checked={this.state.createNewUser}

                            onChange={() => {
                              // this.props.closeUserSelctionPopup();
                              this.openNewUserRegisterationModal();
                            }} />
                        </div>


                      </div>
                    </div>
                  }

                  <div className='button1 mt-2'>
                    {/* <button className="btn1 none_btn" >None of the above</button> */}
                    <button type="submit" className='btn1 sub_btn' onClick={() => {
                      // this.props.closeUserSelctionPopup();
                      if ((primaryuser && primaryuser?.length > 0)) {
                        this.setState({ createNewUser: !this.state.createNewUser, showFamilyMembersRelationshipModal: true }, () => {
                        });

                      }
                      else {
                        ToastComponent.warning("Please select primary user");
                        return;
                      }

                    }}>Continue</button>
                  </div>
                </div>
              </div>
            </div> :
            <div className="p-3">
              <p className='prof_text'>
                The profile you selected as your primary profile is,
              </p>
              <div className="create_box mb-3">
                <div className="user_detail p-2 ml-3">
                  <p className='text_header'> {primaryuser && primaryuser.length > 0 && CommonUtil.getFormattedName(
                    primaryuser[0]?.title,
                    primaryuser[0]?.fName,
                    primaryuser[0]?.lName
                  )}</p>
                  {primaryuser[0]?.memberShipId ? <p>Registration ID: # {primaryuser[0]?.memberShipId}</p> : ''}
                  <p>{Format.toDate(primaryuser[0]?.createdTime, 'dd MMMM yyyy')}</p>
                </div>
              </div>


              {this.state?.lisUserDetails?.lisExistingUsers.map((familyUser, userindex) => {

                return (
                  (familyUser?.primaryUser != true) ? (
                    <>
                      <div className="sub_detail my-3">
                        <p className="user_header mb-2"> {CommonUtil.getFormattedName(
                          familyUser?.title,
                          familyUser?.fName,
                          familyUser?.lName
                        )}</p>
                        <div className="dropdown show">

                          <select className='drop-down relation_text' onChange={(e) => {
                            // familyUser.relationShip = Number(e.target.value);

                            lisUserDetails.lisExistingUsers[userindex].relationShip = Number(e.target.value);
                            this.setState({ lisUserDetails: lisUserDetails })

                          }} value={familyUser?.relationShip.toString()}  >
                            {/* <option value="0">{t('REGISTRATION.SELECT_RELATION')}</option> */}
                            <option value="1">{t('REGISTRATION.SPOUSE')}</option>
                            <option value="2">{t('REGISTRATION.MOTHER')}</option>
                            <option value="3">{t('REGISTRATION.FATHER')}</option>
                            <option value="4">{t('REGISTRATION.DAUGHTER')}</option>
                            <option value="5">{t('REGISTRATION.SON')}</option>
                            <option value="6">{t('REGISTRATION.MOM_IN_LAW')}</option>
                            <option value="7">{t('REGISTRATION.FATHER_IN_LAW')}</option>
                            <option value="8">{t('REGISTRATION.SIBLING')}</option>
                            <option value="9">{t('REGISTRATION.GRAND_MOTHER')}</option>
                            <option value="10">{t('REGISTRATION.GRAND_FATHER')}</option>
                            <option value="11">{t('REGISTRATION.DAUGHTER_IN_LAW')}</option>
                            <option value="12">{t('REGISTRATION.SON_IN_LAW')}</option>
                            <option value="13">{t('REGISTRATION.OTHERS')}</option>
                          </select>
                        </div>
                      </div>




                    </>

                  ) : ''



                );
              })}
              <div className="row">
                <div className="col-sm-3">
                  <button type="submit" className='btn1 sub_btn' onClick={() => { this.onSubmitClickHanlder() }}>Submit</button>

                </div>
                <div className="col-sm-3">
                  <button type="submit" className='btn1 sub_btn' onClick={() => { this.setState({ showFamilyMembersRelationshipModal: false }) }}>Back</button>

                </div>
              </div>


            </div>
          }

          <div
            className="modal fade"
            id="newUserRegistrationModal"
            role="dialog"
            data-keyboard="false"
            data-backdrop="static"

          >
            <div className="modal-dialog">
              <div className="modal-content">

                <div className="modal-header">
                  <h6 className="modal-title txt-primary"> ADD PRIMARY USER</h6>
                </div>
                <div className="modal-body primary_user">
                  <div className="container py-4">
                    <div className="row d-block register">
                      <div className={"col-md-12 px-0"}>

                        <div className="form-group row">
                          <label className="col-sm-3"></label>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3" htmlFor="title">
                            {t("REGISTRATION.TITLE")}
                            <sup>
                              <i
                                className="fa fa-asterisk"
                                aria-hidden="true"
                              ></i>
                            </sup>
                          </label>
                          <div className="col-sm-9">
                            <select
                              id="title"
                              className="form-control"
                              value={CommonUtil.toString(userDetails?.title)}
                              onChange={(e) => {
                                this.setState(
                                  {
                                    userDetails: {
                                      ...userDetails,
                                      title: e.target.value,
                                    },
                                  },
                                  () => {
                                    this.onTitleChange();
                                  }
                                );
                              }}
                            >
                              <option value="undefined">
                                {t("REGISTRATION.SELECT_TITLE")}
                              </option>
                              {this.titlesList.map((title) => (
                                <option key={title} value={title}>
                                  {title}.
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3" htmlFor="yourName">
                            {t("REGISTRATION.NAME")}
                            <sup>
                              <i
                                className="fa fa-asterisk"
                                aria-hidden="true"
                              ></i>
                            </sup>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              id="yourName"
                              autoComplete="off"
                              value={CommonUtil.toString(userDetails.fName)}
                              onChange={(e) => {
                                let fName = Validations.validateText(
                                  e.target.value,
                                  30
                                );
                                this.setState({
                                  userDetails: {
                                    ...userDetails,
                                    fName,
                                  },
                                });
                              }}
                              placeholder="Eg: John"
                              name="fName"
                            ></input>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-3" htmlFor="dob">
                            {t("REGISTRATION.DATE_OF_BIRTH")}
                            <sup>
                              <i
                                className="fa fa-asterisk"
                                aria-hidden="true"
                              ></i>
                            </sup>
                          </label>
                          <div className="col-sm-9">
                            <DateRangePicker
                              id="dob"
                              key={`datepicker`}
                              dateMillis={userDetails?.dob}
                              onChange={(date) => {
                                this.setState({
                                  userDetails: {
                                    ...userDetails,
                                    dob: date.getTime(),
                                  },
                                  newMemberDate: date,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3" htmlFor="gender">
                            {t("REGISTRATION.GENDER")}
                            <sup>
                              <i
                                className="fa fa-asterisk"
                                aria-hidden="true"
                              ></i>
                            </sup>
                          </label>
                          <div className="col-sm-9">
                            <label
                              className="input_label mr-4 mb-0"
                              htmlFor="female"
                            >
                              <input
                                type="radio"
                                name="gender"
                                className="female"
                                id="female"
                                value="Female"
                                onChange={(event) => {
                                  this.setState({
                                    userDetails: {
                                      ...userDetails,
                                      gender: event.target.value,
                                    },
                                  });
                                }}
                                checked={
                                  (userDetails.gender + "").toLowerCase() ===
                                  "female"
                                }
                              />
                              <span className="input_gender input_female"></span>
                              <span className="text-center gender female">
                                &nbsp;{t("REGISTRATION.FEMALE")}
                              </span>
                            </label>
                            <label
                              className="input_label mr-4 mb-0"
                              htmlFor="male"
                            >
                              <input
                                type="radio"
                                name="gender"
                                className="male"
                                id="male"
                                value="Male"
                                onChange={(event) => {
                                  this.setState({
                                    userDetails: {
                                      ...userDetails,
                                      gender: event.target.value,
                                    },
                                  });
                                }}
                                checked={
                                  (userDetails.gender + "").toLowerCase() ===
                                  "male"
                                }
                              />
                              <span className="input_gender input_male"></span>
                              <span className="text-center gender male">
                                &nbsp;{t("REGISTRATION.MALE")}
                              </span>
                            </label>
                            <label
                              className="input_label mb-0"
                              htmlFor="others"
                            >
                              <input
                                type="radio"
                                name="gender"
                                id="others"
                                className="others"
                                onChange={(event) => {

                                  this.setState({
                                    userDetails: {
                                      ...userDetails,
                                      gender: event.target.value,
                                    },
                                  });
                                }}
                                value="Others"
                                checked={
                                  (userDetails.gender + "").toLowerCase() ===
                                  "others"
                                }
                              />
                              <span className="input_gender input_others"></span>
                              <span className="text-center gender others">
                                &nbsp;{t("REGISTRATION.OTHERS")}
                              </span>
                            </label>
                          </div>
                        </div>
                        {/* relationship */}

                        <React.Fragment>
                          <div className="form-group row">
                            <label
                              className=" col-sm-3 your-name your_nameinput"
                              htmlFor="altMobileNumber"
                            >
                              {t("common:ALT_MOBILE")}
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                id="altMobileNumber"
                                autoComplete="off"
                                value={CommonUtil.toString(
                                  userDetails.contactInfo?.alternateMobile
                                )}
                                onChange={(e) => {
                                  let alternateMobile =
                                    Validations.validateInteger(
                                      e.target.value,
                                      +Config.portal.contactNoMaxRange
                                    );
                                  this.setState({
                                    userDetails: {
                                      ...userDetails,
                                      contactInfo: {
                                        ...userDetails.contactInfo,
                                        alternateMobile,
                                      },
                                    },
                                  });
                                }}
                                name="altMobileNumber"
                              ></input>
                            </div>
                          </div>
                        </React.Fragment>

                        <div className="form-group row">
                          <label
                            className=" col-sm-3 your-name your_nameinput"
                            htmlFor="email"
                          >
                            {t("REGISTRATION.EMAIL")}
                            {this.props.isFamilyMemAdd ||
                              this.props.isFamilyMemEdit ? (
                              ""
                            ) : (
                              <sup>
                                <i
                                  className="fa fa-asterisk"
                                  aria-hidden="true"
                                ></i>
                              </sup>
                            )}
                          </label>

                          <div className="col-sm-9">
                            <input
                              type="text"
                              id="email"
                              autoComplete="off"
                              disabled={
                                Config?.portal?.loginType?.EMAIL_ID?.enabled
                              }
                              value={CommonUtil.toString(
                                userDetails.contactInfo?.email
                              )}
                              onChange={(e) => {
                                let email = e.target.value + "";
                                this.setState({
                                  userDetails: {
                                    ...userDetails,
                                    contactInfo: {
                                      ...userDetails.contactInfo,
                                      email,
                                    },
                                    updateEmail: true,
                                  },
                                });
                              }}
                              name="email"
                            ></input>
                          </div>
                        </div>

                        {enableUploadProofDocuments == true && (
                          <div className="form-group row">
                            <label className="col-sm-3">
                              {t("REGISTRATION.UPLOAD_PROOF_DOCUMENTS")}
                            </label>

                            <div className="col-sm-9">
                              <label className="custom-input-file w-100">
                                <input
                                  type="file"
                                  id="uploadReportFile"
                                  name="reportFile"
                                  value={""}
                                  onChange={(e) => {
                                    this.uploadFile(e);
                                  }}
                                  multiple
                                  accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                />
                                <span className="file-custom"></span>
                              </label>
                              {/* <button className="done-txt link-txt float-right file_upload" onClick={() => { this.() }}>upload</button> */}

                              <ul className="list-group file-upload">
                                {RegistrationService.uploadedFiles?.map(
                                  (file, fIndex) => (
                                    <li
                                      className="list-group-item list-group-item-action"
                                      key={fIndex + file?.name}
                                    >
                                      {file?.name}
                                      <span
                                        className="remove-icon rounded-circle done-txt ml-1 pointer-cursor"
                                        onClick={() => {
                                          RegistrationService.uploadedFiles?.splice(
                                            fIndex,
                                            1
                                          );
                                          this.setState({ ...this.state });
                                        }}
                                      >
                                        x
                                      </span>
                                    </li>
                                  )
                                )}
                              </ul>
                              {/* </div> */}
                            </div>
                          </div>
                        )}

                        <div className="form-group row">
                          <div className="col-sm-3"></div>
                          <div className="col-sm-6">
                            {/* {this.props.isRelationShipId > 0 ? */}

                            <button
                              type="button"
                              disabled={this.state.disabled}
                              onClick={() => {
                                if (this.state.disabled) {
                                  return;
                                }
                                this.setState({ ...this.state, disabled: true });
                                // this.debounce(
                                this.submitProfile(userDetails)
                                // 1000
                                // );
                              }
                              }
                              className="done-txt link-txt"
                            >
                              {t("REGISTRATION.ADD_MEM")}
                            </button>
                          </div>
                          <div className="col-sm-3">

                            <button
                              type="button"

                              onClick={() => {

                                $("#newUserRegistrationModal").modal("hide");
                              }
                              }
                              className="link-danger cancel_button link-txt"
                            >
                              {t("REGISTRATION.CANCEL")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    withTranslation(["registration", "common"])(primaryuserSelectionComponent)
  )
);

//make other users relationship as other
