import Auth from '../../auth/auth';
import { CryptoUtil } from '../../auth/util/cryptoutil';
import StorageUtil from '../../auth/util/storageUtil';
import AxiosService from '../../base/portal_env_config/axiosService';
import { paths } from '../../base/portal_env_config/urlconstants';
import { BaseResponse } from '../../model/base/baseresponse';

export default class RegistrationService {
    public static uploadedFiles = [];
    public static filetype = [];
    public static async updateProfile(profile: any) {
        return AxiosService.httpPost(paths.UPDATE_PROFILE, profile).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }
    public static async updateFamilyProfile(profile: any) {
        return AxiosService.httpPost(paths.ADD_NEW_FAMILY_PROFILE, profile).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }


    public static async getRegisteredUser(params) {
        return AxiosService.httpGet(paths.USER_SEARCH, params).then((data) => {
            return Promise.resolve(data);
        }).catch((err) => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async registrateWithOTP(profile: any) {
        return AxiosService.httpPost(paths.REGISTRATION_WITH_OTP, profile).then((res) => {
            if ((res.statusCode == 200 || res.statusCode == 201) && (res.data.statusCode == 200 || res.data.statusCode == 201)) {
                Auth.setLoginResponse(res.data);
                StorageUtil.setDataLocalStorage(StorageUtil.LOGIN_KEY, res.data);
                Auth.setLanguage(res.data.profileId);
            }
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getCityNames(params) {
        return AxiosService.httpGet(paths.GET_CITY_NAMES, params).then((data) => {
            return Promise.resolve(data);
        }).catch((err) => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getProfileData(params): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.GET_PROFILES, params).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

}