import { ServiceItem } from '../service/serviceitem';
import { Product } from '../product/product';
import { InvoiceBaseDetails } from './invoicebasedetails';
import { Pharmacy } from '../pharmacy/pharmacy';

export class CartItem extends InvoiceBaseDetails {

    public static CART_ITEM_TYPE_PACKAGE = 1;
    public static CART_ITEM_TYPE_INVESTIGATIONS = 2;
    public static CART_ITEM_TYPE_PHARMACY = 3;
    public static CART_ITEM_TYPE_PROCEDURE = 4;
    public static CART_ITEM_TYPE_IMMUNISATION = 5;
    public static CART_ITEM_TYPE_PRODUCT = 6;
    public static CART_ITEM_TYPE_ONBOARDING = 7;
    public static CART_ITEM_TYPE_APP_INCENTIVE = 21;

    public cartItemType: number;

    public productList: Array<Product>;
    public serviceList: Array<ServiceItem>;
    public pharmacyList: Array<Pharmacy>;

    public couponApplied: boolean;

    //transient variable
    public isReset: boolean;
    public convertedDocumentUrlList: Array<String>;

    public walletApply: boolean;


    public static getcartItemType(cartItemType: number): string {
        let cartItemTypeString: string = '';

        switch (cartItemType) {
            case CartItem.CART_ITEM_TYPE_PACKAGE: {
                cartItemTypeString = "PACKAGE";
                break;

            }
            case CartItem.CART_ITEM_TYPE_INVESTIGATIONS: {
                cartItemTypeString = "INVESTIGATIONS";
                break;
            }
            case CartItem.CART_ITEM_TYPE_PHARMACY: {
                cartItemTypeString = "PHARMACY";
                break;
            }
            case CartItem.CART_ITEM_TYPE_PROCEDURE: {
                cartItemTypeString = "PROCEDURE";
                break;

            }
            case CartItem.CART_ITEM_TYPE_IMMUNISATION: {
                cartItemTypeString = "IMMUNISATION";
                break;
            }
            case CartItem.CART_ITEM_TYPE_PRODUCT: {
                cartItemTypeString = "PRODUCT";
                break;
            }
            case CartItem.CART_ITEM_TYPE_ONBOARDING: {
                cartItemTypeString = "ONBOARDING";
                break;
            }
        }
        return cartItemTypeString;

    }

}