import React, { Component } from 'react';
import connect from "../layout.connect";
import { withTranslation } from '../../../../i18n';
import Link from 'next/link';
import { Config } from '../../../base/portal_env_config/config';
import PopCityConst from '../../../../public/vdc/popularCitiesConstants';
import './footer2.style.scss';
import Validations from '../../../base/utils/validations';
import LayoutService from '../layout.service';
import ToastComponent from '../widgets/toast/toast.component';
import { Accordion } from "react-bootstrap";
import "antd/dist/antd.css";
import LocationDropdwnService from '../../util-components/hs-location/hs-location.service';
import CommonUtil from '../../../base/utils/commonUtil';
import RouterUtil from '../../../base/utils/routerUtil';
import DiagnosticService from '../../diagnostics/diagnostic.service';
import uniqBy from "lodash/uniqBy";
import { ServiceItem } from '../../../model/service/serviceitem';


class Footer2 extends Component {
    props: any;

    state: {
        userEmail: '',
        // diagFooterCentresList: []
    }

    constructor(props) {
        super(props);
    }

    subscribeToNewsletter() {
        let userDetails = {
            name: '',
            email: '',
            mobileNo: '',
            city: '',
            enquiryFor: 'Subscription',
            status: 0,
            type: "NewsLetter"
        };
        if (Validations.isValidEmail(this.state?.userEmail)) {

            userDetails.email = this.state?.userEmail;

            LayoutService.subscribe(userDetails)
                .then((response) => {

                    if (response.statusCode == 200 || response.statusCode == 201) {
                        ToastComponent.success('Subscribed to news letter.');
                        // this.setState({ userEmail: '' });
                    }
                }).catch((err) => {
                    // this.setState({ userEmail: '' });
                    ToastComponent.warning('Something went wrong. Please try again');
                });


        } else {
            // this.setState({ userEmail: '' });
            ToastComponent.warning("Enter valid EmailId");
        }

        this.setState({ userEmail: '' });


    }

    goToDetail(item, isPackage: boolean = false) {
        let address = LocationDropdwnService.getSerLocation();
        let cityName = address?.cityName ? address?.cityName : 'hyderabad';
        if (isPackage) {
            return `/health-checkup/${CommonUtil.convertToSlug(cityName)}/${item?.slugName}`;
        } else {
            return `/test/${CommonUtil.convertToSlug(cityName)}/${item?.slugName}`;
        }

    }

    getPopularTests(mostBookedTestList) {
        let UIPopularTests = [];

        mostBookedTestList = uniqBy(mostBookedTestList, (obj: ServiceItem) => obj?.serviceId);

        mostBookedTestList.forEach((testItem, index) => {
            UIPopularTests.push(
                <li key={testItem.serviceId + "-service-ftr-" + index} className='footer_menu'>
                    <Link href={this.goToDetail(testItem)}>
                        <a className="pointer-cursor">{testItem?.serviceName}</a>
                    </Link>
                </li>
            );
        });
        return UIPopularTests;
    }

    getPopularCitiesTests() {
        let UIPopularCity = [];
        PopCityConst.popularCity.forEach((city, cityIndex) => {
            UIPopularCity.push(
                <li key={city.id + "-ftr-tests-" + cityIndex} className='footer_menu'>
                    <Link key={city.id + "-ftr-tests-link-" + cityIndex} href={'/test/' + CommonUtil.convertToSlug(city.title)}>
                        <a onClick={() => { this.props.setLocation(city); }} className="pointer-cursor">Lab tests in {city.title}</a>
                    </Link>
                </li>
            );
        });
        return UIPopularCity;
    }

    getPopularCitiesPackages() {
        let UIPopularCity = [];
        PopCityConst.popularCity.forEach((city, cityIndex) => {
            UIPopularCity.push(
                <li key={city.id + "-pkg-" + cityIndex} className='footer_menu'>
                    <Link key={city.id + "-pkg-lnk-" + cityIndex} href={'/health-checkup/' + CommonUtil.convertToSlug(city.title)}>
                        <a onClick={() => { this.props.setLocation(city); }} className="pointer-cursor">Health checkups in {city.title}</a>
                    </Link>
                </li>
            );
        });
        return UIPopularCity;
    }

    getpopularRadiologyService() {
        let UIPopularRadiologyService = [];

        UIPopularRadiologyService.push(

            <>
                <li className='footer_menu'>
                    <Link href='https://www.vijayadiagnostic.com/services/multi-slice-ct'>
                        <a className="pointer-cursor">CT Scan</a>
                    </Link>
                </li>
                <li className='footer_menu'>
                    <Link href='https://www.vijayadiagnostic.com/services/mri-scan'>
                        <a className="pointer-cursor">MRI Scan</a>
                    </Link>
                </li>
                <li className='footer_menu'>
                    <Link href='https://www.vijayadiagnostic.com/services/ultrasound-scanning-colour-doppler'>
                        <a className="pointer-cursor">ULTRASOUND</a>
                    </Link>
                </li>
                <li className='footer_menu'>
                    <Link href='https://www.vijayadiagnostic.com/services/digital-x-ray'>
                        <a className="pointer-cursor">X-RAY</a>
                    </Link>
                </li>
                <li className='footer_menu'>
                    <Link href='https://www.vijayadiagnostic.com/services/pet-ct'>
                        <a className="pointer-cursor">PET CT</a>
                    </Link>
                </li>
                <li className='footer_menu'>
                    <Link href='https://www.vijayadiagnostic.com/services/mri-3t'>
                        <a className="pointer-cursor">MRI-3T</a>
                    </Link>
                </li>
                <li className='footer_menu'>
                    <Link href='https://www.vijayadiagnostic.com/services/cardiology'>
                        <a className="pointer-cursor">CARDIOLOGY</a>
                    </Link>
                </li>
            </>


        );

        return UIPopularRadiologyService;
    }

    getOurPresence() {
        let UIPopularCity = [];
        PopCityConst.popularCity.forEach((city, cityIndex) => {
            UIPopularCity.push(
                <li key={city.id + "-centre-" + cityIndex} className='footer_menu'>
                    <Link href={'/find-a-centre/' + CommonUtil.convertToSlug(city.title)}>
                        <a onClick={() => { this.props.setLocation(city); }} className="pointer-cursor">{city.title}</a>
                    </Link>
                </li>
            );
        });
        return UIPopularCity;
    }

    getOurPocTest(diagFooterCentresList) {
        const serviceListUi =
            <ul className='popular_menu'>
                {diagFooterCentresList && diagFooterCentresList.length > 0 && diagFooterCentresList?.map((poc, index) => {
                    return (
                        <li key={`find-centre-` + index}>
                            <Link key={`find-centre-link-` + index} href={`/find-a-centre/${CommonUtil.convertToSlug(poc?.address?.cityName)}/${poc?.pocCenterDetails?.slugName}`}>
                                <a className="pointer-cursor">
                                    <span>
                                        {poc?.pocName}
                                    </span>
                                </a>
                            </Link>
                        </li>
                    )
                })
                }
            </ul>
        return serviceListUi;
    }

    componentDidMount() {
   
    }


    render() {
        let data = this.props?.dashboard?.dashboardData;

        let mostbookedList = [];
        if (data?.mostBookedDiagnosticList && data?.mostBookedDiagnosticList?.length > 0) {
            mostbookedList = data?.mostBookedDiagnosticList?.length >= 16 ? data?.mostBookedDiagnosticList?.slice(0, 16) : data?.mostBookedDiagnosticList;
        }
        let address = LocationDropdwnService.getSerLocation();
        let cityName = address?.cityName ? address?.cityName : (address?.title ? address.title : 'Hyderabad');
        let diagFooterCentresList = this.props.diagnostic.diagnosticFooterCentresList
        let path = RouterUtil.getPathName();
        let isFindACentre = false;
        if (path && path.length > 0 && path.includes('find-a-centre/')) {
            isFindACentre = true;
        }

        return (
            <React.Fragment>

                <div className='container'>
                    <div className='row'>
                        {!isFindACentre && mostbookedList?.length > 0 &&
                            (<div className='col-lg-12 col-md-12 col-sm-12 lab_test'>
                                <h4 className="center mb-2 newd-border text-dark">Popular Lab Tests in {cityName}</h4>
                                <ul className='popular_menu'>
                                    {this.getPopularTests(mostbookedList)}
                                </ul>
                            </div>)
                        }
                        {!isFindACentre && diagFooterCentresList && diagFooterCentresList?.length > 0 &&
                            <div className='col-lg-12 col-md-12 col-sm-12 lab_test mt-4'>
                                <h4>Our Presence in {cityName}</h4>
                                {this.getOurPocTest(diagFooterCentresList)}
                            </div>
                        }
                        <div className='col-lg-12 col-md-12 col-sm-12 lab_test mt-4'>
                            <h4 className="center mb-2 newd-border text-dark">Popular Lab Tests in Other Cities</h4>
                            <ul className='popular_menu'>
                                {this.getPopularCitiesTests()}
                            </ul>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 lab_test mt-4'>
                            <h4 className="center mb-2 newd-border text-dark">Popular Health Packages in Other Cities</h4>
                            <ul className='popular_menu'>
                                {this.getPopularCitiesPackages()}
                            </ul>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12 lab_test mt-4'>
                            <h4 className="center mb-2 newd-border text-dark">Popular Radiology Service</h4>
                            <ul className='popular_menu'>
                                {this.getpopularRadiologyService()}
                            </ul>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12 lab_test mt-4'>
                            <h4 className="center mb-2 newd-border text-dark">Our Presence</h4>
                            <ul className='popular_menu'>
                                {this.getOurPresence()}
                            </ul>
                        </div>
                    </div>
                </div>
                <footer className='footer bg-transparent '>
                    <section className="footer d-block d-sm-none">
                        <div className="container header_footer_menu">
                            <div className='row'>
                                <div className='col-12'>
                                    {/* <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className='footer_shadow text-dark'>
                                                Popular Tests
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {(mostbookedList && mostbookedList?.length > 0) ?
                                                    <ul>
                                                        {mostbookedList?.map((testItem, index) =>
                                                            <li className='footer_menu' key={`mostbooked_footer-popular_${index}`}>
                                                                <Link href={this.goToDetail(testItem)}>
                                                                    <a className="pointer-cursor">{testItem?.serviceName}</a>

                                                                </Link>
                                                            </li>
                                                        )}
                                                    </ul>
                                                    : <div>{t('common:NO_DATA_FOUND')}</div>}
                                            </Accordion.Body>

                                        </Accordion.Item>
                                    </Accordion>

                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className='footer_shadow text-dark'>
                                                Lab tests across India
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <ul>
                                                    {this.getPopularCitiesTests()}
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>

                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className='footer_shadow text-dark'>
                                                Health checkups across India
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <ul>
                                                    {this.getPopularCitiesPackages()}
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion> */}

                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className='footer_shadow text-dark'>
                                                Quick Links
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <ul>
                                                    <li key='about-us-ftr' className='footer_menu'>
                                                        <Link href={`/about-us`}>
                                                            <a className="pointer-cursor">About Us</a>
                                                        </Link></li>
                                                    <li key='our-team-ftr' className='footer_menu'><Link href={`/our-team`}>
                                                        <a className="pointer-cursor">Our Team</a>
                                                    </Link></li>
                                                    <li key='accreditation-ftr' className='footer_menu'><Link href={`/accreditation`}>
                                                        <a className="pointer-cursor">Accreditation</a>
                                                    </Link></li>
                                                    <li key='awards-ftr' className='footer_menu'><Link href={`/awards-and-accolades`}>
                                                        <a className="pointer-cursor">Awards and Accolades</a>
                                                    </Link></li>
                                                    <li key='csr-ftr' className='footer_menu'><Link href={`/csr-activity`}>
                                                        <a className="pointer-cursor">Corporate Social Responsibility</a>
                                                    </Link></li>
                                                    <li key='tele-radio-ftr' className='footer_menu'><Link href={`/teleradiology`}>
                                                        <a className="pointer-cursor">Tele Radiology</a>
                                                    </Link></li>
                                                    <li key='test-services-ftr' className='footer_menu'><Link href={`/services/`}>
                                                        <a className="pointer-cursor">Services</a>
                                                    </Link></li>
                                                    <li key='health-check-ftr' className='footer_menu'><Link href={`/health-checkup/`}>
                                                        <a className="pointer-cursor">Health Checkups</a>
                                                    </Link></li>
                                                    <li key='home-sample-ftr' className='footer_menu'><Link href={`/diagnostic/home-sample-collection/`}>
                                                        <a className="pointer-cursor">Home Sample Collection</a>
                                                    </Link></li>
                                                    <li key='download-report-ftr' className='footer_menu'><Link href={`/mydashboard?type=2`}>
                                                        <a className="pointer-cursor">Download Reports</a>
                                                    </Link></li>
                                                    <li key='corp-well-ftr' className='footer_menu'><Link href={`/corporate-wellness/`}>
                                                        <a className="pointer-cursor">Corporate Wellness</a>
                                                    </Link></li>
                                                    <li key='covid-ftr' className='footer_menu'><Link href={`/covid-19/`}>
                                                        <a className="pointer-cursor">COVID-19</a>
                                                    </Link></li>
                                                    <li key='contact-us-ftr' className='footer_menu'><Link href={`/contact-us`}>
                                                        <a className="pointer-cursor">Contact Us</a>
                                                    </Link></li>
                                                    <li key='virtual-tour-ftr' className='footer_menu'><Link href={`/centres-virtual-tour`}>
                                                        <a className="pointer-cursor">virtual tour</a>
                                                    </Link></li>
                                                    <li key='careers-ftr' className='footer_menu'><Link href={`/careers`}>
                                                        <a className="pointer-cursor">Careers</a>
                                                    </Link></li>
                                                    <li key='blog-ftr' className='footer_menu'><Link href={`/blog`}>
                                                        <a onClick={() => { window.location.href = 'https://www.vijayadiagnostic.com/blog'; }} className="pointer-cursor">Blog</a>
                                                    </Link></li>
                                                    <li key='investors-ftr' className='footer_menu'><Link href={`/investors/financial-results`}>
                                                        <a className="pointer-cursor">Investors</a>
                                                    </Link></li>
                                                    <li key='fqa-ftr' className='footer_menu'><Link href={`/faqs`}>
                                                        <a className="pointer-cursor">FAQs</a>
                                                    </Link></li>

                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>

                                <div className='col-12 mb-4'>

                                    <p className='newletter-txt'>Subscribe to News Letter</p>
                                    <div className="row">
                                        <div className='col-7'>
                                            <input
                                                type="text"
                                                className="bg-transparent email_input"
                                                id="mobileemail"
                                                autoComplete="off"

                                                value={this.state?.userEmail}
                                                onChange={(e) => {
                                                    this.setState({
                                                        userEmail: e.target.value
                                                    })


                                                }} placeholder='Enter your email'
                                                name="email">
                                            </input>
                                        </div>
                                        <div className='col-5 pl-0'>
                                            <button className='subscribebutton' onClick={() => { this.subscribeToNewsletter() }}>Subscribe</button>
                                        </div>

                                    </div>
                                    <div className='socialmedia-info mt-3'>
                                        <a href="mailto:info@vijayadiagnostic.com" className="blackfont">
                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                            &nbsp;info@vijayadiagnostic.com</a>
                                    </div>
                                    <div className='socialmedia-info mt-3'>
                                        <a className="blackfont" href="tel:04021000000">
                                            <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;040 - 2100 0000
                                        </a>
                                    </div>


                                    <div className='socialmedia-info mt-3'>
                                        <p>Social Media</p>
                                        <ul>
                                            <li><a target="_blank" href={Config.portal.instagram} aria-label="instagram" rel="nofollow"><img src='/common-img/socialicon/Instagram.png' alt=''></img></a></li>
                                            <li><a target="_blank" href={Config.portal.facebook} aria-label="facebook" rel="nofollow"><img src='/common-img/socialicon/Facebook.png' alt=''></img></a></li>
                                            <li><a target="_blank" href={Config.portal.twitter} aria-label="twitter" rel="nofollow"><img src='/common-img/socialicon/Twitter.png' alt=''></img></a></li>
                                            <li><a target="_blank" href={Config.portal.linkedin} aria-label="linkedin" rel="nofollow"><img src='/common-img/socialicon/Linkedin.png' alt=''></img></a></li>
                                            <li><a target="_blank" href={Config.portal.youtube} aria-label="youtube-play" rel="nofollowInstagram"><img src='/common-img/socialicon/Youtube.png' alt=''></img></a></li>
                                        </ul>
                                    </div>
                                    <div className='app-info d-block d-md-none'>
                                        <ul>
                                            <li>
                                                <a href="https://apple.co/3SwQoww" target="_blank"
                                                    className="" aria-label="appLink" rel="nofollow">
                                                    <img src="/common-img/app-store.png" alt="app-storeicon" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://bit.ly/vdcappn" target="_blank"
                                                    className="" aria-label="appLink" rel="nofollow">
                                                    <img src="/common-img/google-play.png" alt="google-playicon" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                    <section className='outer_part'>
                        <div className='container'>
                            {/* <div className='row'>
                                <div className='col-4'>
                                    <h4 className="footer_shadow text-dark">Popular Tests</h4>

                                    {(mostbookedList && mostbookedList?.length > 0) ?
                                        <ul>
                                            {mostbookedList?.map((testItem, index) =>
                                                <li className='footer_menu' key={`footer+${index}`}>
                                                    <Link href={this.goToDetail(testItem)}>
                                                        <a className="pointer-cursor">{testItem?.serviceName}</a>

                                                    </Link>
                                                </li>
                                            )

                                            }

                                        </ul>
                                        : <div>{t('common:NO_DATA_FOUND')}</div>}
                                </div>
                                <div className='col-4'>
                                    <h4 className="footer_shadow text-dark">Lab tests across India</h4>
                                    <ul>
                                        {this.getPopularCitiesTests()}
                                    </ul>
                                </div>
                                <div className='col-4'>
                                    <h4 className="footer_shadow text-dark"> Health checkups across India</h4>
                                    <ul>
                                        {this.getPopularCitiesPackages()}
                                    </ul>
                                </div>
                            </div> */}
                            <div className='row mt-5'>
                                <div className='col-12'>
                                    <h5 className="footer_shadow text-dark"> Quick Links</h5>
                                </div>
                                <div className='col-md-6'>
                                    <div className='row'>

                                        <div className='col-6 mb-4'>
                                            <ul>
                                                <li className='footer_menu'><Link href={`/about-us`}>
                                                    <a className="pointer-cursor">About Us</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/our-team`}>
                                                    <a className="pointer-cursor">Our Team</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/accreditation`}>
                                                    <a className="pointer-cursor">Accreditation</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/awards-and-accolades`}>
                                                    <a className="pointer-cursor">Awards and Accolades</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/csr-activity`}>
                                                    <a className="pointer-cursor">Corporate Social Responsibility</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/teleradiology`}>
                                                    <a className="pointer-cursor">Tele Radiology</a>
                                                </Link></li>

                                            </ul>
                                        </div>
                                        <div className='col-6 mb-4'>
                                            <ul>
                                                <li className='footer_menu'><Link href={`/services/`}>
                                                    <a className="pointer-cursor">Services</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/health-checkup/`}>
                                                    <a className="pointer-cursor">Health Checkups</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/diagnostic/home-sample-collection/`}>
                                                    <a className="pointer-cursor">Home Sample Collection</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/mydashboard?type=2`}>
                                                    <a className="pointer-cursor">Download Reports</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/corporate-wellness/`}>
                                                    <a className="pointer-cursor">Corporate Wellness</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/covid-19/`}>
                                                    <a className="pointer-cursor">COVID-19</a>
                                                </Link></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='row'>
                                        <div className='col-4 mb-4'>
                                            <ul>
                                                <li className='footer_menu'><Link href={`/contact-us`}>
                                                    <a className="pointer-cursor">Contact Us</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/centres-virtual-tour`}>
                                                    <a className="pointer-cursor">Virtual tour</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/careers`}>
                                                    <a className="pointer-cursor">Careers</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/blog/`}>
                                                    <a onClick={() => { window.location.href = 'https://www.vijayadiagnostic.com/blog'; }} className="pointer-cursor">Blog</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/investors/financial-results`}>
                                                    <a className="pointer-cursor">Investors</a>
                                                </Link></li>
                                                <li className='footer_menu'><Link href={`/faqs`}>
                                                    <a className="pointer-cursor">FAQs</a>
                                                </Link></li>

                                            </ul>
                                        </div>
                                        <div className='col-8 mb-4'>

                                            <p className='newletter-txt'>Subscribe to News Letter</p>
                                            <div className="row">
                                                <div className='col-7'>
                                                    <input
                                                        type="text"
                                                        className="bg-transparent email_input"
                                                        id="mobileemail"
                                                        autoComplete="off"

                                                        value={this.state?.userEmail}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                userEmail: e.target.value
                                                            })


                                                        }} placeholder='Enter your email'
                                                        name="email">
                                                    </input>
                                                </div>
                                                <div className='col-5 pl-0'>
                                                    <button className='subscribebutton' onClick={() => { this.subscribeToNewsletter() }}>Subscribe</button>
                                                </div>

                                            </div>
                                            <div className='socialmedia-info mt-3'>
                                                <a href="mailto:info@vijayadiagnostic.com" className="blackfont">
                                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                                    &nbsp;info@vijayadiagnostic.com</a>
                                            </div>
                                            <div className='socialmedia-info mt-3'>
                                                <a className="blackfont" href="tel:04021000000">
                                                    <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;040 - 2100 0000
                                                </a>
                                            </div>


                                            <div className='socialmedia-info mt-3'>
                                                <p>Social Media</p>
                                                <ul>
                                                    <li><a target="_blank" href={Config.portal.instagram} aria-label="instagram" rel="nofollow"><img src='/common-img/socialicon/Instagram.png' alt=''></img></a></li>
                                                    <li><a target="_blank" href={Config.portal.facebook} aria-label="facebook"><img src='/common-img/socialicon/Facebook.png' alt=''></img></a></li>
                                                    <li><a target="_blank" href={Config.portal.twitter} aria-label="twitter"><img src='/common-img/socialicon/Twitter.png' alt=''></img></a></li>
                                                    <li><a target="_blank" href={Config.portal.linkedin} aria-label="linkedin"><img src='/common-img/socialicon/Linkedin.png' alt=''></img></a></li>
                                                    <li><a target="_blank" href={Config.portal.youtube} aria-label="youtube-play"><img src='/common-img/socialicon/Youtube.png' alt=''></img></a></li>
                                                </ul>
                                            </div>
                                            <div className='app-info d-block d-md-none'>
                                                <ul>
                                                    <li>
                                                        <a href="https://apple.co/3SwQoww" target="_blank"
                                                            className="" aria-label="appLink">
                                                            <img src="/common-img/app-store.png" alt="app-storeicon" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://bit.ly/vdcappn" target="_blank"
                                                            className="" aria-label="appLink">
                                                            <img src="/common-img/google-play.png" alt="google-playicon" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <hr className='m-0' /> */}
                        </div>
                    </section>
                    <div className='copyright_info'>
                        <div className='container'>
                            <p>© Copyright 2024 By Vijaya Diagnostic Centre Limited. All Rights Reserved
                                | <Link href={(Config.portal.sitemap ? `//${Config.portal.sitemap}` : '/sitemap')}><a className="" aria-label="sitemap">Sitemap</a></Link>
                                | <Link href={(Config.portal.privacypolicy ? `//${Config.portal.privacypolicy}` : '/privacy-policy')}><a className="" aria-label="privacy">Privacy Policy</a></Link>
                                | <Link href={(Config.portal.termsAndConditions ? `//${Config.portal.termsAndConditions}` : '/terms-condition')}><a className="" aria-label="terms">Terms of Use</a></Link>
                            </p>
                        </div>
                    </div>
                </footer>
            </React.Fragment >
        )
    }
}

export default connect(withTranslation(['common'])(Footer2));